<template>
   <section class="content-container">
      <router-view/>
   </section>
</template>

<!-- <template>
   <div class="recognize">
      <div class="recognize-sidebar">
         <SideBar />
      </div>
      <div class="recognize-container">
         <img src="@/assets/img/forma_1.svg" alt="" class="shape-one" />
         <img src="@/assets/img/forma_3.svg" alt="" class="shape-tw" />
         <img src="@/assets/img/forma_2.svg" alt="" class="shape-tr" />
         <div class="recognize-body">
            <div class="recognize-body-top">
               <SectionTitle :title="title" subtitle="" />

               <ProfileNav />
            </div>
            <router-view />
            <footerSec></footerSec>
         </div>
      </div>
   </div>
</template> -->

<script>
//import SideBar from "@/components/SideBar.vue";
// import SectionTabs from "../components/Section/SectionTabs.vue";
/* import SectionTitle from "@/components/Section/SectionTitle.vue";
import ProfileNav from "@/components/Profile/ProfileNav.vue";
import footerSec from "@/components/Section/SectionFooter.vue"; */
export default {
/*    components: {
      SideBar,
      SectionTitle,
      ProfileNav,
      footerSec,
   }, */
   data() {
      return {
         title: "Reconoce",
      };
   },
   mounted() {
      setInterval(() => {
         this.checkIfUserIsLogged();
      }, 100);
      this.checkMyLogin(this.$route);
   },
   methods: {
      goTo(route) {
         var current_route = this.$route.name;
         if (route == current_route) {
            return;
         } else {
            this.$router.push({ name: route });
         }
      },
      activeTab(tab) {
         var current_route = this.$route.name;
         if (tab == current_route) {
            return "active";
         }
      },
   },
};
</script>